import { useState } from "react";
import { Button, Stack, useTheme } from "@mui/material";

import Iconify from "../../../components/Iconify";
import { DateRangePicker } from "@roy2022/materialui-daterange-picker-updated";
import moment from "moment";
import { IconButton } from "@mui/material";


type Props = {
	parameter: string;
	setParameter: (parameter: string) => void;
};


export default function DateWrapper({parameter, setParameter}: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack direction="row" spacing={1}>
      <Stack border={`1px solid ${theme.palette.primary.main}`} borderRadius={1} direction="row">
        <Button
          variant="text"
          startIcon={<Iconify icon={"akar-icons:calendar"} />}
          onClick={() => setOpen(true)}
          sx={{
            height: "100%",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
        >
          {parameter || "Seleccione una fecha"}
        </Button>
        <Stack position="absolute" zIndex={100}>
          <DateRangePicker
            wrapperClassName="datePicker"
            open={open}
            toggle={() => {
              setOpen(false);
            }}
            closeOnClickOutside={true}
            onChange={(dateRange) => {
              if (dateRange.startDate) {
                const dateStart = moment(dateRange.startDate).format(
                  "MM/DD/YYYY"
                );
                const dateEnd = moment(dateRange.endDate).format("MM/DD/YYYY");
                const dateFilter = `${dateStart}-${dateEnd}`;
                setParameter(dateFilter);
                setOpen(false);
              }
            }}
            definedRanges={[]}
          />
        </Stack>

      </Stack>
      {!!parameter ?
        (<IconButton
          onClick={() => {
            setParameter("");
          }}
        >
          <Iconify icon="eva:close-square-fill" width={30} height={30} />
        </IconButton>)
        : null
      }
    </Stack>
  );
}
