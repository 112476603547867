import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from "../store";
import axios from "../../utils/axios";
import { AUTHORIZER_PATH } from "../../constants/paths";
import { RolesState } from "types/roles_state";
import { RoleManager } from "types/user";
import { closeLoading, openLoading } from "./utils";

const path = AUTHORIZER_PATH;

const initialState: RolesState = {
    roles: [],
    dialog: {
        open: false,
    }

}

const slices = createSlice({
    name: "roles",
    initialState,
    reducers: {
        setRoles(state, actions: { payload: RoleManager[] }) {
            state.roles = actions.payload;
        },
        removeLocalRole(state, actions: { payload: string }) {
            state.roles = state.roles.map((role) => {
                if (role.id = actions.payload) {
                    return { ...role, status: "DELETED" };
                }
                return role;
            });
        },
        setModal(state, action) {
            state.dialog = action.payload;
        }
    }
});

export default slices.reducer;

export function openModal(data?: any) {
    dispatch(slices.actions.setModal({ open: true, data }))
}

export function closeModal() {
    dispatch(slices.actions.setModal({ open: false }))
}

export function searchRoles() {
    return async () => {
        try {
            const response = await axios.get<{ groups: RoleManager[] }>(`${path}/roles`);
            dispatch(slices.actions.setRoles(response.data.groups));
        } catch (error) {
            console.log(error)
        }
    }
}

export function createRoles(role: any) {
    return async () => {
        try {
            openLoading();
            const response = await axios.post(`${path}/roles`, role);
            return response.data         
        } catch (error) {
            console.log('Error createRoles: ', error)
        } finally {
            closeLoading();
        }
    }
}

export function editRole(role: any) {
    return async () => {
        return axios.patch(`${path}/roles`, role);
    }
}

export function deleteRoles(id: string) {
    return async () => {
        try {
            openLoading();
            await axios.patch<RoleManager[]>(`${path}/roles`, { id, status: "DELETED" });
            await dispatch(searchRoles());
        } catch (error) {
            console.log(error)
        } finally {
            closeLoading();
        }
    }
}