import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from "../store";
import axios from "../../utils/axios";
import { DialogType, User } from "../../@types/user";
import { UsersState } from "../../@types/users_state";
import { USER_PATH } from "../../constants/paths";
import { UserFilterResponse } from "types/user-filter-response";

const path = USER_PATH;

const initialState: UsersState = {
    users: [],
    loading: false,
    dialogNewUser: { open: false },
    pageInfo: {
        count: 0,
        totalCount: 0,
        endCursor: "",
        startCursor: ""
    },
    noData: false,
    loadingData: true,
}

const slices = createSlice({
    name: "users",
    initialState,
    reducers: {
        setLoadingData(state, action) {
            state.loadingData = action.payload;
        },
        setUsers(state, actions) {
            state.users = actions.payload.users
            state.pageInfo = actions.payload.info
        },
        setLoading(state, actions: { payload: boolean }) {
            state.loading = actions.payload
        },
        dialogNewOrder(state, actions: { payload: DialogType<any> }) {
            state.dialogNewUser = actions.payload;
        },
        setNoData(state, actions: { payload: boolean }) {
            state.noData = actions.payload
        },
    }
});

export default slices.reducer;


export function searchUsers(params: Record<string, any>) {
    dispatch(slices.actions.setNoData(false))
    return async () => {
        try {
            const filters = {...params};
            for (const key in filters) {
                filters[key] = filters[key].value ?? filters[key];
            }
            const response = await axios.get<UserFilterResponse>(`${path}/user`, { params: filters })
            const nodata = response.data.users.edges.length === 0
            dispatch(slices.actions.setNoData(nodata))
            const users = response.data.users.edges.map((o) => {
                return ({...o.node, name: o.node.names, lastName: o.node.surname})});
            dispatch(slices.actions.setUsers({ users, info: response.data.users.pageInfo }));
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(slices.actions.setLoadingData(false));
        }
    }
}


export function cleanUsers() {
    dispatch(slices.actions.setUsers({ users: [], info: initialState.pageInfo }));
}


export function setLoadingData(loading: boolean) {
    dispatch(slices.actions.setLoadingData(loading))
}

export function saveUser(user: User) {
    return async () => {
        const data = {
            "names": user.name.toLocaleUpperCase(),
            "last_name": user.lastName.toLocaleUpperCase(),
            "surname": user.secondLastName.toLocaleUpperCase(),
            "email": user.email,
            "group": [
                user.rol
            ]
        }

        return axios.post(`${path}/user`, data);
    }
}

export function openDialogUser() {
    dispatch(slices.actions.dialogNewOrder({ open: true }))
}

export function closeDialogUser() {
    dispatch(slices.actions.dialogNewOrder({ open: false }))
}