import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import NotistackProvider from "./components/NotistackProvider";
import UsersList from "./containers/users-list/UsersList";
import ThemeProvider from "./theme";
import { store } from "./redux/store";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

const Root = () => {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <NotistackProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/users" element={<UsersList />} />
                </Routes>
              </BrowserRouter>
            </NotistackProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};

export default Root;
