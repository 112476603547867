import { TableRow, TableCell, Typography, IconButton } from "@mui/material";
import moment from "moment";
import { RoleManager } from '../../../@types/user';
import Iconify from "../../../components/Iconify";
import { useDispatch } from "../../../redux/store";
import { deleteRoles } from "../../../redux/slices/roles";
import { openConfirmDeleteDialog } from "../../../redux/slices/utils";
import { openModal } from "../../../redux/slices/roles";

type Props = {
  row: RoleManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function RoleTableRow({
  row,
  selected,
}: Props) {

  const dispatch = useDispatch();

  const { name, created_at, id } = row;

  const handleDeleteRole = () => {
    return dispatch(deleteRoles(`${id}`));
  }

  const handleEditRole = () => {
    return openModal(row);
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {moment(created_at! * 1000).format('DD/MM/YYYY')}
      </TableCell>
      <TableCell sx={{ alignItems: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <IconButton
          color="primary"
          onClick={handleEditRole}
        >
          <Iconify icon={"eva:edit-fill"} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => openConfirmDeleteDialog(handleDeleteRole)}
        >
          <Iconify icon={"eva:trash-2-outline"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
