import {
  Table,
  TableBody,
  TableContainer,

} from "@mui/material";
import { useState } from "react";
import { TABLE_HEAD_FUNCTIONALITIES } from "../../../constants/headersTable/header";
import { SubModule } from '../../../@types/functionality';
import TableHeadCustom from "../../../components/table/TableHeadCustom";
import useTable from "../../../hooks/useTable";
import FunctionalityTableRow from "../../../sections/funcionalities/form/FunctionalityTableRow";

type Props = {
  rows: SubModule[];
  onEditRow: (arg: string) =>void;
  onDeleteRow: (arg: string) =>void;
};

export default function FunctionalityTable({
  rows,
  onDeleteRow,
  onEditRow,
}: Props) {

  const {
    dense,
    page,
    rowsPerPage,
  } = useTable();
  const [headerTable] = useState(TABLE_HEAD_FUNCTIONALITIES);
 
  return (

    <TableContainer sx={{ minWidth: 300,  maxHeight: "25vh", position: "relative", flex: 1, mt: 1 }} >
      <Table
        size={dense ? "small" : "medium"}
        stickyHeader>
        <TableHeadCustom
          headLabel={headerTable}
          rowCount={10}
        />

        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: any) => (
              <FunctionalityTableRow
                key={row.id}
                row={row}
                onDeleteRow={()=>onDeleteRow(row.name)}
                onEditRow={() => onEditRow(row.name)} />
            ))}


        </TableBody>
      </Table>
    </TableContainer>)
}
