import {
    Button,
    MenuItem,
    Stack,
    Typography,
    Dialog,
    Divider,
} from "@mui/material";
import * as Yup from "yup";
import { useSelector } from "../../../redux/store";
import { StoreState } from '../../../redux/rootReducer';
import FormProvider from "../../../components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "types/user";
import { closeDialogUser, saveUser } from "../../../redux/slices/users";
import { dispatch } from '../../../redux/store';
import RHFTextField from "../../../components/hook-form/RHFTextField"
import RHFSelect from "../../../components/hook-form/RHFSelect"
import { closeLoading, openLoading, setAlert } from "../../../redux/slices/utils";
import { useMutation } from "@tanstack/react-query";
import { Errors } from "../../../components/Alert"


const schema = Yup.object().shape({
    name: Yup.string().required("Nombre requerido").matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El nombre solo puede contener letras").min(2, "El nombre debe terner al menos dos caracteres").max(30, "El nombre puede tener hasta 30 caracteres"),
    lastName: Yup.string().required("Apellido requerido").matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras").min(2, "El apellido debe terner al menos dos caracteres").max(30, "El apellido puede tener hasta 30 caracteres"),
    secondLastName: Yup.string().required("Apellido requerido").matches(/^[a-zA-Zá-úÁ-ÚÑñ ]*$/, "El apellido solo puede contener letras").min(2, "El apellido debe terner al menos dos caracteres").max(30, "El apellido puede tener hasta 30 caracteres"),
    email: Yup.string().required("Correo requerido").email("Correo invalido"),
    rol: Yup.string().required("Cargo requerido"),
});


export default function CreateUserDialog() {
    /* const dialog = useSelector<StoreState, DialogType<any>>((state) => state.users.dialogNewUser);
    const { roles } = useSelector<StoreState, RolesState>((state) => state.roles); */
    const { roles: { roles }, users: { dialogNewUser } } = useSelector<StoreState>(state => state);

    const methods = useForm<User>({
        mode: "onChange",
        resolver: yupResolver(schema),
        shouldUnregister: false,
        defaultValues: {
            name: "",
            lastName: "",
            secondLastName: "",
            email: "",
        },

    });

    const { handleSubmit } = methods;

    const handleCloseDialog = () => {
        methods.reset({ name: "", lastName: "", secondLastName: "", email: "" }, { keepValues: false });
        closeDialogUser();
    };

    const mutation = useMutation({
        mutationFn: (user: User) => {
            return dispatch(saveUser(user));

        },
        onMutate: () => openLoading(),
        onSuccess: () => {
            setAlert(Errors.E005);
            handleCloseDialog();
            closeLoading();
        },
        onError: (error: any) => {
            setAlert({
                type: "error",
                message: error.message,
                open: true,
            });

            closeLoading();
        }
    });

    const handleOnSubmit = async (user: User) => {
        const validation = await methods.trigger();
        if (validation) {
            mutation.mutate(user);
        }
    };
    return (
        <Dialog {...dialogNewUser} onClose={handleCloseDialog} fullWidth maxWidth="md" >
            <Stack direction="row" spacing={2} >
                <Stack direction="column" spacing={2} sx={{ width: '100%', paddingY: 2 }} >
                    <Stack
                        sx={{ width: '100%', paddingX: 5 }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h4" color="black" >
                            CREAR USUARIO
                        </Typography>
                    </Stack>
                    <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(handleOnSubmit)}
                    >
                        <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                            <Divider sx={{ margin: 1 }} />
                            <Typography variant="h6" component="div">
                                Datos Personales
                            </Typography>
                            <Stack direction="row" spacing={2}>
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <RHFTextField
                                    label="Nombre"
                                    sx={{ width: "100%" }}
                                    name="name"
                                    inputProps={{
                                        style: { textTransform: "uppercase" },
                                    }}
                                />
                                <Stack direction="column" spacing={1}>
                                    <Stack direction="row" spacing={2}>
                                        <RHFTextField
                                            label="Primer apellido"
                                            sx={{ width: "100%" }}
                                            name="lastName"
                                            inputProps={{
                                                style: { textTransform: "uppercase" },
                                            }}
                                        />
                                        <RHFTextField
                                            label="Segundo apellido"
                                            sx={{ width: "100%" }}
                                            name="secondLastName"
                                            inputProps={{
                                                style: { textTransform: "uppercase" },
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                            <Divider sx={{ margin: 1 }} />
                            <Typography variant="h6" component="div">
                                Datos de Contacto
                            </Typography>
                            <Stack direction="row" spacing={2}>
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <RHFTextField
                                    label="Correo electrónico"
                                    sx={{ width: "100%" }}
                                    name="email"

                                />
                                <Stack direction="column" spacing={2}>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={1} sx={{ paddingX: 5 }}>
                            <Divider sx={{ margin: 1 }} />
                            <Typography variant="h6" component="div">
                                Roles
                            </Typography>
                            <Stack direction="row" spacing={2}>
                            </Stack>
                            <Stack direction="column" spacing={2}>
                                <RHFSelect
                                    name="rol"
                                    placeholder="Roles"
                                    label="Roles"
                                >
                                    {roles.map((role) => (
                                        <MenuItem key={role.id} value={role.name}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>

                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing={1} alignItems={'center'} margin={3}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Guardar
                                </Button>
                                <Button
                                    variant="contained"
                                    color='error'
                                    onClick={handleCloseDialog}
                                >
                                    Cancelar
                                </Button>
                            </Stack>
                        </Stack>
                    </FormProvider>
                </Stack>
            </Stack>
        </Dialog>

    )

}
