import * as Yup from "yup";
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {  useMemo, useState } from 'react';
import { Button, CardHeader } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from "../../../components/hook-form/FormProvider";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import { SCHEMA_CREATE_MODULE } from "../../../constants/schemas/schema_create_funtionality";
import CreateSubmodule from "./CreateSubmodulo";
import { dispatch } from "../../../redux/store";
import { createFuntionality, getFunctionalities } from "../../../redux/slices/functionalities";

type Props = {
  onCancel: any,
  currentModulo: any
};


export default function CreateFunctionality({ onCancel, currentModulo}: Props) {
  const [dataSubmodule, setDataSubmodule] = useState<any>()

  const SchemaModule = Yup.object().shape(SCHEMA_CREATE_MODULE)

  const defaultValueSubmodule = useMemo(
    () => ({
      module: currentModulo?.module || "",
      submodules: currentModulo?.submodules || []
    }),
    [currentModulo]
  )

  const methoModule = useForm<any>({
    resolver: yupResolver(SchemaModule),
    defaultValues: defaultValueSubmodule,
  })

  const {
    handleSubmit,
  } = methoModule;

  const saveInfo = async (data: any) => {
    if (dataSubmodule.length > 0) {
      const info = {
        module: data.module,
        submodules: dataSubmodule
      }
      await dispatch(createFuntionality(info))
      dispatch(getFunctionalities())
      onCancel()
    }
  }

  return (
    <>
      <FormProvider methods={methoModule} onSubmit={handleSubmit(saveInfo)}>
        <Card sx={{ mb: 0.5 }}>
          <CardHeader
            title='Crear Funcionalidad'
            titleTypographyProps={{ variant: 'h3' }}
            sx={{mb:-3, mt:-3}}
          >

          </CardHeader>
          <CardContent>
            <RHFTextField name="module" label="Nombre de la Módulo" />
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title='Submódulos'
            titleTypographyProps={{ variant: 'h4' }}
            sx={{mb:-3, mt:-2}}           
          />
          <CardContent>
            <CreateSubmodule setSubmodulo={setDataSubmodule} />
          </CardContent>
        </Card>
        <Box sx={{ mt: 1, mb:-2}}>
          <Stack direction="row" justifyContent="center" width="100%" gap={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{minWidth:100}}
              size="medium"
            >Crear</Button>
            <Button
              variant="contained"
              color="error"
              onClick={onCancel}
              sx={{minWidth:100}}
              size="medium"
            >Cancelar</Button>
          </Stack>
        </Box>
      </FormProvider>
    </>
  );
}
