
import * as Yup from "yup";

export const SCHEMA_CREATE_FUNTIONALITY = {
    name: Yup.string().required("El campo es requerido").min(4, 'Debe contener al menos 4 caracteres'),
    description: Yup.string().required("El campo es requerido").min(4, 'Debe contener al menos 4 caracteres'),
}

export const SCHEMA_CREATE_MODULE = {
    module: Yup.string().required("El campo es requerido").min(4, 'Debe contener al menos 4 caracteres'),
}