import {
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { searchUsers, cleanUsers, setLoadingData } from "../../../redux/slices/users";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../components/Iconify";
import DateWrapper from "./DateWrapper";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { UsersState } from "types/users_state";


export type State = {
  key: string,
	value?: string;
};

type Props = {
	limit: number;
	page: number;
	action: number | undefined;
  stateFilter: (arg: any)=>void;
}


const initState: State = {
	key: "identification",
};



export default function FilterUsers({limit, page, action, stateFilter}: Props) {
  const [state, setState] = useState<State>(initState);

	let [parameter, setParameter] = useState({});
  const [dateFilter, setDateFilter]=useState('');
  const [filter]=useState(true);
  const dispatch = useDispatch();

  const searchSeveral = () => {
		parameter['uuid'] ? parameter['uuid'] = parameter['uuid'].value.replace(/ /g, ",") : null;
		 dispatch(searchUsers(parameter) as any);
	};

  const { pageInfo  }: any = useSelector<UsersState>(
		(state) => state.users
	);

  const handleDelete = (id: string) => {
		delete parameter[id];
		setParameter({ ...parameter, 'limit': 25 });

	};

	useQuery({
		queryKey: [
			"user-filter",
			/* { limit, value: valueSearch, page } */
			{ ...parameter, limit, page, dateFilter }

		],
		queryFn: ({ queryKey }) => {
			cleanUsers();
			setLoadingData(true);
			const q = (queryKey[1] as any);
			parameter = { ...parameter, limit: q.limit };
			if(dateFilter) {
        parameter["createdAt"] = dateFilter;
      }
			if (action === 1) {
				parameter["after"] = pageInfo.endCursor;
			}
			if (action === -1) {
				parameter["before"] = pageInfo.startCursor;
			}
			return searchSeveral();
		},
		refetchOnWindowFocus: false,
	});

  return (
    <Stack
      spacing={1}
      direction={{ xs: "column-reverse", md: "row" }}
      height={{ md: 80 }}
      sx={{ py: 2, px: 2 }}
      zIndex={10}
    >
      <DateWrapper parameter={dateFilter} setParameter={setDateFilter}/>
      <Stack flex={1}>
        <TextField
          fullWidth
          sx={{ height: "100%" }}
          onChange={(event)=> {
            setState({ ...state, value: event.target.value });
            setParameter({ ...parameter, ['identification']: { "value": event.target.value.toLocaleUpperCase(), "label": event.target.value.toLocaleUpperCase() } })

            if (!event.target.value) {
              handleDelete(state.key);
              return;
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment >
            ),
            sx: { height: "100%" },
          }}
        />
      </Stack>
      <Stack justifyContent="center">
        <Typography variant="body2">
          Mostrar usuarios inactivos
          <Switch value={filter} onChange={(ev)=>stateFilter(ev.target.checked)}/>
        </Typography>
      </Stack>
    </Stack>
  );
}
