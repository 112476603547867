import { ReactNode, createContext } from "react";
// utils
import getColorPresets, {
  colorPresets,
  defaultPreset,
} from "../utils/getColorPresets";
// config
import { defaultSettings } from "../config";
// @type
import { SettingsContextProps } from "../components/settings/type";

const initialState: SettingsContextProps = {
  ...defaultSettings,
  setColor: defaultPreset,
  colorOption: [],
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  return (
    <SettingsContext.Provider
      value={{
        ...initialState,
        setColor: getColorPresets(initialState.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main,
        })),
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
