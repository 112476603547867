import { TableRow, TableCell, Typography, IconButton, Chip, Stack } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { Functionality } from "types/functionality";

type Props = {
  row: Functionality;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export function FunctionalityTableRow({ row, onEditRow, onDeleteRow }: Props) {

  const { module, submodules } = row;

  return (
    <TableRow hover>
      <TableCell sx={{ alignItems: "center", padding: "5px 10px" }}>
        <Typography variant="subtitle2" noWrap>
          {module}
        </Typography>
        <Stack paddingY={1} spacing={1} direction="row">
          {submodules.map((s) => <Chip label={s.name} variant="outlined" color="primary" />)}
        </Stack>
      </TableCell>

      <TableCell align="right" sx={{ padding: "5px 10px" }}>
        <IconButton
          color="primary"
          onClick={onEditRow}
        >
          <Iconify icon={"eva:edit-fill"} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={onDeleteRow}
        >
          <Iconify icon={"eva:trash-2-outline"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
