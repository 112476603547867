import { useTheme } from "@mui/material/styles";
import { TableRow, TableCell, Typography, IconButton } from "@mui/material";
import { UserManager } from "../../../@types/user";
import Label from "../../../components/Label";
import Iconify from "../../../components/Iconify";

type Props = {
  row: UserManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserTableRow({
  row,
  selected,
}: // onEditRow,
  // onDeleteRow,
  Props) {
  const theme = useTheme();

  const { name, email, lastName, group, status, createdAt } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ alignItems: "center", padding: "5px 10px" }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>
      <TableCell sx={{ alignItems: "center", padding: "5px 10px" }}>
        <Typography variant="subtitle2" noWrap>
          {lastName}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ padding: "5px 10px" }}>{email}</TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize", padding: "5px 10px" }}>
        {group}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize", padding: "5px 10px" }}>
        {new Date(createdAt*1000).toDateString()}
      </TableCell>

      <TableCell align="left" sx={{ padding: "5px 10px" }}>
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(status === "INACTIVE" && "error") || "success"}
          sx={{ textTransform: "capitalize" }}
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="right" sx={{ padding: "5px 10px" }}>
        <IconButton
          color="primary"
          onClick={() => {
            //TODO: impl method
          }}
        >
          <Iconify icon={"eva:edit-fill"} />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            //TODO: impl method
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
