import { SettingsValueProps } from "./components/settings/type";
import { PATH_DASHBOARD } from "./routes/paths";

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || "";

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app;

export const defaultSettings: SettingsValueProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: "horizontal",
  themeColorPresets: "blue",
  themeStretch: false,
};
