import * as Yup from "yup";
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { SubModules } from '../../../@types/functionalities';
import { IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from "../../../components/hook-form/FormProvider";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import FunctionalityTable from "./FunctionalityTable";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { SCHEMA_CREATE_FUNTIONALITY } from "../../../constants/schemas/schema_create_funtionality";
import { SubModule } from "types/functionality";

type Props = {
  setSubmodulo: (arg: any) => void,
};

const createRow = (name: any, description: any) => {
  return { name: name, description: description };
};

export default function CreateSubmodule({ setSubmodulo }: Props) {
  const [buttonEdit, setButtonEdit] = useState(false);
  const [data, setData] = useState<SubModules[]>([])
  const [currentSubmodulo, setCurrentSubmodulo] = useState<SubModules | null>()
  const [currentSubmoduloUpdate, setUCurrentSubmoduloUpdate] = useState<SubModules | null>()

  const SchemaSubmodule = Yup.object().shape(SCHEMA_CREATE_FUNTIONALITY)

  const defaultValueSubmodule = useMemo(
    () => ({
      name: currentSubmodulo?.name || "",
      description: currentSubmodulo?.description || "",
    }),
    [currentSubmodulo]
  )

  const methoSubmodule = useForm<SubModules>({
    resolver: yupResolver(SchemaSubmodule),
    defaultValues: defaultValueSubmodule,
  })

  const {
    reset,
    handleSubmit,
    //formState,
  } = methoSubmodule;

  useEffect(() => {
    setSubmodulo(data)
  },[data]);


  const handleAddRow = async (submodulo: SubModule) => {
    if (!buttonEdit) {
      let itemFind: any = {};
      itemFind = data.find(item => item.name === submodulo.name);
      if (buttonEdit === false && itemFind === undefined) {
        setData((prevRows) => [...prevRows, createRow(submodulo.name, submodulo.description)]);
      }
      reset(defaultValueSubmodule)
    } else {
      data.map((item) => {
        if (item.name === currentSubmoduloUpdate?.name) {
          item.name = submodulo?.name || '';
          item.description = submodulo?.description || '';
          setButtonEdit(false)
        }
      })
      await setData(data)
    }
    reset(defaultValueSubmodule)
  };

  const handleDeleteRow = (name: string) => {
    let dataFiltered = data.filter(item => item.name !== name);
    setData(dataFiltered);
  }

  const handleUpdateRow = (name: string) => {
    setButtonEdit(true)
    const dataFiltered = data.find(item => item.name === name);
    setCurrentSubmodulo(dataFiltered)
    setUCurrentSubmoduloUpdate(dataFiltered)
  }

  return (
    <>
      <FormProvider methods={methoSubmodule} >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={5}>
              <RHFTextField name="name" label="Nombre del submódulo" />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField name="description" label="Descripción del Submódulo" />
            </Grid>
            <Grid item xs={1}>
              {buttonEdit === false && <IconButton color="success" onClick={handleSubmit(handleAddRow)} size='large'>
                <AddCircleSharpIcon />
              </IconButton>}
              {buttonEdit === true && <IconButton color="success" onClick={handleSubmit(handleAddRow)} size='large'>
                <CheckCircleIcon />
              </IconButton>}
            </Grid>
          </Grid>
        </Box>
        <Box>
          {data.length > 0 &&
            <FunctionalityTable
              rows={data}
              onDeleteRow={handleDeleteRow}
              onEditRow={handleUpdateRow}
            />
          }
        </Box>
      </FormProvider>
    </>
  );
}