import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { FunctionalitiesState } from "types/functionalities_state";
import { dispatch } from "../store";
import {  AUTHORIZER_PATH } from "../../constants/paths";
import { closeLoading, openLoading } from "./utils";
import { DialogType } from "types/user";


const path = AUTHORIZER_PATH;

const initialState: FunctionalitiesState = {
    functionalities: { list: [], detail: {}},
    loading: false,
    dialogNewFuntionality: { open: false },
};

const slices = createSlice({
    name: "functionalities",
    initialState: initialState,
    reducers: {
        setFunctionalities(state, actions) {
            state.functionalities['list'] = actions.payload;
        },
        setFuncionalityById(state, actions) {
            state.functionalities['detail'] = actions.payload;
        },
        dialogNewOrder(state, actions: { payload: DialogType<any> }) {
            state.dialogNewFuntionality = actions.payload;
        },
        
    },
});

export default slices.reducer;


export function getFunctionalities() {
    return async () => {
        try {
            const response = await axios.get(`${path}/functionalities`);
            dispatch(slices.actions.setFunctionalities(response.data));
        } catch (error) {

        }
    }
}

export function getFunctionalityById(params: Record<string, any>) {
    return async () => {
        try {
            const response = await axios.get(`${path}/functionalities`,{ params });
            dispatch(slices.actions.setFuncionalityById(response.data));
        } catch (error) {

        }
    }
}

export function createFuntionality(functionality: any) {
    return async () => {
        try {
            openLoading();
            const response = await axios.post(`${path}/functionalities`, functionality);
            return response.data         
        } catch (error) {
            console.log('Error createRoles: ', error)
        } finally {
            closeLoading();
        }
    }
}

export function openDialogFunctionality() {
    dispatch(slices.actions.dialogNewOrder({ open: true }))
}

export function closeDialogFunctionality() {
    dispatch(slices.actions.dialogNewOrder({ open: false }))
}