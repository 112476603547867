import {
  Container,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import { _userList, _userRoles } from "../../_mock";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import useTabs from "../../hooks/useTabs";
import { RoleContainer } from "../../sections/role/list/RoleContainer";
import TableUsers from "../../sections/users/list/TableUsers";
import ButtonTabs from "../../sections/ButtonTabs";
import { dispatch, useSelector } from "../../redux/store";
import { RolesState } from "types/roles_state";
import { useQuery } from "@tanstack/react-query";
import { UtilsState } from "../../@types/utils_state";
import { LoadingScreen } from "../../components/LoadingScreen";
import TableFunctionalities from "../../sections/funcionalities/TableFunctionalities";
import { ConfirmDeletePopup } from "../../components/ConfirmDeletePopup";
import { RoleDialog } from "../../sections/role/dialog/RoleDialog";
import { SnackAlert } from "../../components/Alert";
import CreateUserDialog from "../../sections/users/dialog/CreateUserDialog";
import { getFunctionalities } from "../../redux/slices/functionalities";
import { FunctionalityDialog } from "../../sections/funcionalities/dialog/FunctionalityDialog";
import { searchUsers } from "../../redux/slices/users";


export enum UserActions {
  Users = '1',
  Roles = '2',
  Functionalities = '3',
}

const USERS_TABS = [
  { value: "1", label: "Usuarios" },
  { value: "2", label: "Roles" },
  { value: "3", label: "Funcionalidades" },
];

export default function UsersList() {
  const { roles } = useSelector<RolesState>((state) => state.roles);
  const { currentTab, onChangeTab } = useTabs("1");
  const { loading } = useSelector<UtilsState>((state) => state.utils);

  useQuery({
    queryKey: ["roles"],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return dispatch(searchUsers({limit : 5}));
    },
  });

  useQuery({
    queryKey: ["functionalities"],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return dispatch(getFunctionalities());
    },
  });
  
  return (
    <Page height="calc(100vh - 100px)">
      <Container maxWidth={false} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <HeaderBreadcrumbs
          heading=""
          links={[
            { name: "Usuarios", href: PATH_DASHBOARD.skeleton.root },
            { name: "Listado de registros" },
          ]}
        />

        <Stack mb={2} direction="row" justifyContent="space-between">
          <Tabs
            value={currentTab}
            onChange={onChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="tabs for navigation"
          >
            {USERS_TABS.map((t) => (
              <Tab {...t} />
            ))}
          </Tabs>
          <ButtonTabs currentTab={currentTab} />
        </Stack>

        {/* TODO: impl tabs route */}
        {currentTab === UserActions.Users && <TableUsers />}
        {currentTab === UserActions.Roles && <RoleContainer roles={roles} />}
        {currentTab === UserActions.Functionalities && <TableFunctionalities />}

      </Container>
      {loading ? <LoadingScreen /> : null}
      <ConfirmDeletePopup />
      <CreateUserDialog />
      <RoleDialog />
      <FunctionalityDialog />
      <SnackAlert />
    </Page >
  );
}