import { createSlice } from "@reduxjs/toolkit";
import { AlertState, DialogDelete, UtilsState } from "types/utils_state";
import { dispatch } from "../store";

const initialState: UtilsState = {
    loading: false,
    dialogDelete: { open: false },
    error: {
        open: false,
        message: "",
        type: "info",
    }
}

const slices = createSlice({
    name: "utils",
    initialState: initialState,
    reducers: {
        setLoading(state, actions) {
            state.loading = actions.payload;
        },
        setConfirmDialog(state, actions: { payload: DialogDelete }) {
            state.dialogDelete = actions.payload;
        },
        setError(state, action) {
            state.error = action.payload
        },
    }
});

export default slices.reducer;

export function openLoading() {
    dispatch(slices.actions.setLoading(true));
}

export function closeLoading() {
    dispatch(slices.actions.setLoading(false));
}

export function openConfirmDeleteDialog(callback: VoidFunction) {
    dispatch(slices.actions.setConfirmDialog({ open: true, actionDelete: callback }));
}

export function closeConfirmDeleteDialog() {
    dispatch(slices.actions.setConfirmDialog({ open: false, actionDelete: undefined }));
}

export function setAlert(error: AlertState) {
    dispatch(slices.actions.setError(error))
}