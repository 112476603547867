import { Dialog, DialogContent, Table, TableBody, TableContainer } from "@mui/material";
import useTable, { getComparator } from "../../hooks/useTable";
import { TableHeadCustom } from "../../components/table";
import { Functionality } from "types/functionality";
import { FunctionalityTableRow } from "./list/FunctionalityTableRow";
import { useQuery } from "@tanstack/react-query";
import { getFunctionalities, getFunctionalityById } from '../../redux/slices/functionalities';
import { dispatch, useSelector } from "../../redux/store";
import { FunctionalitiesState } from "types/functionalities_state";
import { useState } from "react";

const TABLE_HEAD_FUNCTIONALITIES = [
    { id: "module", label: "Modulo", align: "left", width: "calc(100% - 115px)" },
    { id: "", label: "Acciones", align: "center", width: "115px" },
];

export default function TableFunctionalities() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        selected,
        onSelectAllRows,
        onSort,
    } = useTable();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const { functionalities } = useSelector<FunctionalitiesState>((state) => state.functionalities);

    const dataFiltered = applySortFilter({
        tableData: functionalities.list,
        comparator: getComparator(order, orderBy),
    });

    useQuery({
        queryKey: ["get-functionalities"],
        queryFn: () => functionalities.list.length === 0 ?
            dispatch(getFunctionalities()) :
            new Promise((resolve) => resolve(functionalities)),
    });

    const handleDeleteRow = (_: string) => {
        //TODO: imp delete function
    };

    const handleEditRow = (id: string) => {
        handleClickOpen();
        dispatch(getFunctionalityById({ "id": id }));
    };

    return (
        <>
            <TableContainer>
                <Table size={dense ? "small" : "medium"} stickyHeader>
                    <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD_FUNCTIONALITIES}
                        rowCount={functionalities.list.length}
                        numSelected={selected.length}
                        onSort={onSort}
                        onSelectAllRows={(checked) =>
                            onSelectAllRows(
                                checked,
                                functionalities.list.map((row: any) => row.id)
                            )
                        }
                    />
                    <TableBody>
                        {dataFiltered
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <FunctionalityTableRow
                                    key={row.id}
                                    row={row}
                                    onDeleteRow={() => handleDeleteRow(row.id)}
                                    onEditRow={() => handleEditRow(row.id)}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                </DialogContent>
            </Dialog>
        </>)
}


function applySortFilter({
    tableData,
    comparator,
}: {
    tableData: Functionality[];
    comparator: (a: any, b: any) => number;
}) {
    const stabilizedThis = tableData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    tableData = stabilizedThis.map((el) => el[0]);

    return tableData;
}
