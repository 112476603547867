import Button from "@mui/material/Button";
import Iconify from "../components/Iconify";
import { UserActions } from '../containers/users-list/UsersList';
import { openModal } from "../redux/slices/roles";
import { openDialogUser } from "../redux/slices/users";
import { openDialogFunctionality } from "../redux/slices/functionalities";

interface IButtonTabsProps {
    currentTab: string;
}

export default function ButtonTabs({ currentTab }: IButtonTabsProps) {
    const TEXT_BUTTON = {
        [UserActions.Users]: 'usuario',
        [UserActions.Roles]: 'roles',
        [UserActions.Functionalities]: 'funcionalidad',
    }

    const handleOpenModal = () => {
        switch (currentTab) {
            case "1":
                openDialogUser();
                break;
            case "2":
                openModal();
                break;
            case "3":
                openDialogFunctionality()
                break;
        }
    }

    return (<Button
        variant="contained"
        startIcon={<Iconify icon={"eva:plus-fill"} />}
        onClick={() => handleOpenModal()}
    >
        Crear {TEXT_BUTTON[currentTab || UserActions.Users]}
    </Button>)
}